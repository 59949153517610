
import { defineComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { matchSc } from '@/api/myMatch'

export default defineComponent({
  setup () {
    const router = useRouter()
    const toPolicy = () => {
      router.push({ path: '/policyList' })
    }
    onMounted(() => {
      matchSc().then(res => {
        if (res.data === '' || res.data === null) {
          return true
        } else {
          router.replace({ path: '/quickMatch', query: { socialCreditCode: res.data } })
        }
      })
    })
    return {
      toPolicy
    }
  }
})
