<template>
  <div class="myMatch base_min_width">
    <div class="myMatchTips flex_col">
      <span>暂未评估</span>
      <span>想知道你的企业能申报什么扶持政策？</span>
      <div class="matchBtn pointer" @click="toPolicy">立即匹配</div>
    </div>
  </div>
</template>
<script lang='ts'>
import { defineComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { matchSc } from '@/api/myMatch'

export default defineComponent({
  setup () {
    const router = useRouter()
    const toPolicy = () => {
      router.push({ path: '/policyList' })
    }
    onMounted(() => {
      matchSc().then(res => {
        if (res.data === '' || res.data === null) {
          return true
        } else {
          router.replace({ path: '/quickMatch', query: { socialCreditCode: res.data } })
        }
      })
    })
    return {
      toPolicy
    }
  }
})
</script>
<style lang='scss' scoped>
.myMatch {
  width: 100%;
  background: #f9f9f9;
  padding-top: 210px;
  padding-bottom: 136px;
  .myMatchTips {
    width: 546px;
    margin: auto;
    background: #ffffff;
    border-radius: 6px;
    align-items: center;
    padding-top: 54px;
    padding-bottom: 64px;
    img {
      width: 72px;
      height: 72px;
    }
    & > span:nth-of-type(1) {
      font-size: 22px;
      font-weight: bold;
      color: #333333;
    }
    & > span:nth-of-type(2) {
      margin-top: 13px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
    .matchBtn {
      margin-top: 25px;
      width: 200px;
      height: 48px;
      background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
      box-shadow: 0px 6px 20px rgba(210, 175, 113, 0.3);
      border-radius: 25px;
      line-height: 48px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: #000;
    }
  }
}
</style>
