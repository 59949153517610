import request from '@/utils/request'
import { RequestData } from '@/types/index'

// 获取用户最近一次极速匹配社会信用代码
export function matchSc(data?: RequestData) {
  return request({
    url: '/pla/search/history/pc/matchSc',
    method: 'post',
    data
  })
}
